@import url("https://fonts.googleapis.com/css2?family=Anek+Latin:wght@100..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Anek Latin", sans-serif;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  font-size: 10px;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
  cursor: pointer;
}

ul {
  list-style: none;
}

section {
  width: 100%;
}

.container {
  max-width: 400px;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.lottiePageLoader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
